<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InputFilters from "@/components/form/InputFilters.vue";
import ProductFilters from "@/modules/products/components/ProductFilters.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import FiltersRowMixin from "@/mixins/FiltersRowMixin";

@Component({
  components: { CurrencySelect, InputFilters },
})
export default class ProductFiltersRow extends Mixins(
  FiltersRowMixin,
  ProductFilters
) {}
</script>

<template>
  <input-filters
    ref="inputFilters"
    v-model="obFilterData"
    :model-name="sModelFilterKey"
    :headers="headerValueList"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #name>
      <form-field-text
        v-model="obFilterData.name"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #code>
      <form-field-text
        v-model="obFilterData.code"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #category>
      <categories-select
        v-model="obFilterData.category"
        :dense="true"
        :outlined="false"
        solo
        hide-label
        @change="onUpdateFilters"
      />
    </template>

    <template #offer>
      <form-field-text
        v-model="obFilterData.price"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #actions>
      <div class="text-right">
        <v-btn text color="primary" @click="onResetFilters">
          <v-icon>mdi-find-replace</v-icon>
        </v-btn>
      </div>
    </template>
  </input-filters>
</template>
